import styled from "styled-components";
import React, {createRef, FC} from "react";
import {createStyles, IconButton, List, ListItem, ListItemText, makeStyles, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {institutionalPaths, menuItems} from "./Menu";
import warningImage from "../../assets/images/e.png";
import childrenImage from "../../assets/images/children.png";
import echopeFooter from "../../assets/images/echope-footer.png";
import AppleIcon from '@material-ui/icons/Apple';
import AndroidIcon from '@material-ui/icons/Android';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { useLocation } from 'react-router-dom';

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-basis: auto;
  flex-shrink: 0;
`;

const useStyles = makeStyles(theme => createStyles({
    footerWrapper: {
        display: "flex",
        width: "80%",
        flexDirection: "column",
        alignItems: "center",
        '& *': {
            display: "flex"
        },
        [theme.breakpoints.down("sm")]: {
            width: "95%",
        }
    },
    boxUpWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: '20px 0',
    },
    boxUp: {
        display: "flex",
        flexDirection: "row",
        width: "80%",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            width: "95%",
        }
    },
    boxUpItem: {
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
    },
    boxItemMiddle: {
        display: "flex",
        height: 40,
        alignItems: "center",
        justifyContent: "center",
    },
    boxItemImage: {
        display: "flex",
        height: 70,
        justifyContent: "space-evenly",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: 20,
        paddingBottom: 5
    },
    boxItemWarning: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        flexDirection: "column",
    },
    boxDownWrapper: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#03242F",
        justifyContent: "center",
        borderTopColor: "#fff",
        color: "#fff",
        flexDirection: "column",
    },
    boxDownSeparator: {
        width: "80%",
        height: 1,
        backgroundColor: "#fff",
        padding: 0,
        margin: 0,
        marginTop: 5,
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            width: "95%",
        }
    },
    boxDown: {
        display: "flex",
        paddingTop: 5,
        paddingBottom: 10,
        flexDirection: "row",
        width: "80%",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            width: "95%",
        }
    },
    footerMenu: {
        display: "flex",
        flex: 1,
        padding: 0,
        margin: 0,
        justifyContent: "flex-end",
        flexDirection: "row",
        flexBasis: "auto",
        flexShrink: 0,
        [theme.breakpoints.down("sm")]: {
            marginTop: 10,
            flexDirection: "column"
        }
    },
    footerMenuItem: {
        padding: 0,
        marginRight: 10,
        width: "auto"
    }
}));


// const AndroidIconNew = () => {
//     return (
//         <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">

//             <g>
//                 <title>background</title>
//                 <rect fill="none" id="canvas_background" height="34" width="34" y="-1" x="-1"/>
//             </g>
//             <g>
//                 <title>Layer 1</title>
//                 <path stroke="null" id="svg_3" fill="#A4CA39" d="m7.61069,23.035639c0,1.072649 0.863392,1.936168 1.936168,1.936168l1.301647,0l0.019678,3.996642c0,2.413587 3.610669,2.394101 3.610669,0l0,-3.996642l2.433328,0l0,3.996642c0,2.400596 3.630283,2.407028 3.630283,0l0,-3.996642l1.321324,0c1.059721,0 1.923113,-0.863583 1.923113,-1.936168l0,-11.734818l-16.17621,0l0,11.734818z"/>
//                 <path stroke="null" id="svg_5" fill="#A4CA39" d="m19.682129,3.930689l1.24223,-2.32749c0.182092,-0.324047 -0.273202,-0.535573 -0.435667,-0.251179l-1.255209,2.347188c-2.165734,-0.971949 -4.773593,-1.024863 -7.04329,0l-1.261604,-2.353947c-0.156133,-0.277635 -0.617822,-0.072676 -0.435857,0.251307l1.24223,2.33412c-2.47129,1.282672 -4.136207,3.742381 -4.136207,6.572229l16.20008,0c0,-2.829912 -1.664917,-5.289557 -4.116706,-6.572229zm-7.667507,3.596837c-0.364248,0 -0.663409,-0.317417 -0.663409,-0.694314c0,-0.376832 0.292703,-0.69425 0.663409,-0.69425c0.383749,0 0.682973,0.317417 0.682973,0.69425c-0.000063,0.376961 -0.299287,0.694314 -0.682973,0.694314zm7.394369,0c-0.383686,0 -0.682783,-0.317417 -0.682783,-0.694314c0,-0.376832 0.299161,-0.69425 0.682783,-0.69425c0.364248,0 0.663346,0.317417 0.663346,0.69425c0,0.376961 -0.299097,0.694314 -0.663346,0.694314z"/>
//                 <path stroke="null" id="svg_8" fill="#A4CA39" d="m25.970379,11.408917c-0.776958,0 -1.415006,0.526615 -1.415006,1.196977l0,5.040476c0,0.670404 0.638047,1.205836 1.415006,1.205836c0.792437,0 1.548805,-0.539797 1.548805,-1.205836l0,-5.040476c0,-0.670361 -0.761523,-1.196977 -1.548805,-1.196977z"/>
//                 <path stroke="null" id="svg_9" fill="#A4CA39" d="m5.337848,11.258318c-0.77696,0 -1.415,0.52661 -1.415,1.19697l0,5.04048c0,0.6704 0.63804,1.20583 1.415,1.20583c0.79244,0 1.54881,-0.53979 1.54881,-1.20583l0,-5.04048c0,-0.67036 -0.76153,-1.19697 -1.54881,-1.19697z"/>
//             </g>
//         </svg>
//     );
// }

export const footerRef = createRef<any>();

const FooterBar: FC = () => {
    const styles = useStyles();
    const location = useLocation();

    const isInstitutionalPath = (): boolean => {
        return institutionalPaths().some(
            (path) => path === location.pathname || location.pathname === "/"
        );
    };

    const menus = menuItems().filter(menu => {
        if (isInstitutionalPath() && !menu.showOnInstitutional) return false
        return true;
    });

    return (
        <FooterContainer id="page-footer" ref={footerRef}>
            <div className={styles.boxUpWrapper}>
                <div className={styles.boxUp}>
                    <div className={styles.boxUpItem}>
                        <div className={styles.boxItemWarning} style={{ flex: 1}}>
                            <img src={warningImage}
                                 alt="Beba com moderação"
                                 style={{ width: 58, height: 62 }} />

                        </div>

                        <div className={styles.boxItemImage} style={{paddingTop: 0}}>
                            <img src={childrenImage}
                                alt="Proibido para menores de 18 anos"
                                style={{ width: 143, height: 41 }} />
                        </div>
                    </div>
                    <div className={styles.boxUpItem}>
                        <div className={styles.boxItemMiddle}>
                            <IconButton aria-label="Facebook"
                                        href="https://facebook.com/echopeoficial" target="_blank">
                                <FacebookIcon style={{ fontSize: 32, color: "#007FE6" }} />
                            </IconButton>

                            <IconButton aria-label="Instagram"
                                        href="https://instagram.com/echopeoficial" target="_blank">
                                <InstagramIcon style={{ fontSize: 32, color: "#E94F87" }}/>
                            </IconButton>
                        </div>
                        <div className={styles.boxItemImage}>
                            <img src={echopeFooter}
                                 alt="echope"
                                 style={{ height: 70, paddingTop: 10}} />
                        </div>
                    </div>
                    <div className={styles.boxUpItem}>
                        <div className={styles.boxItemMiddle}>
                            <IconButton aria-label="Apple"
                                        href="https://apps.apple.com/br/app/echope/id1471110013" target="_blank">
                                <AppleIcon style={{ fontSize: 32, color: "#000000" }} />
                            </IconButton>

                            <IconButton aria-label="Play Store"
                                        href="https://play.google.com/store/apps/details?id=br.com.echope" target="_blank">
                                <AndroidIcon style={{ fontSize: 32, color: "#a4ca39" }}/>
                            </IconButton>
                            {/* <AppleIcon style={{ fontSize: 32 }} />
                            <AndroidIconNew /> */}
                        </div>
                        <div className={styles.boxItemImage}>
                            <img src="https://dbechope.s3-sa-east-1.amazonaws.com/images_site/qr_small.png"
                                 alt="Beba com moderação"
                                 style={{ width: 70, height: 70 }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.boxDownWrapper}>
                <div className={styles.boxDown}>
                    <Typography variant="body2">&copy; 2025 echope todos os direitos reservados</Typography>

                    <List className={styles.footerMenu}>
                        {
                            menus.map((item, index) => (
                                <ListItem component={Link}
                                          className={styles.footerMenuItem}
                                          to={item.path}
                                          button
                                          key={index}>
                                    <ListItemText primary={item.label} style={{ margin: 0 }} disableTypography />
                                </ListItem>
                            ))
                        }
                    </List>
                </div>
            </div>
        </FooterContainer>
    )
}

export default FooterBar;
