import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { FC, useEffect, useState } from "react";
import { VideoJsPlayer } from "video.js";
import SectionHeader from "../../components/header";
import VideoPlayer from "../../components/video/VideoPlayer";

interface IVideo {
    title: string;
    description: string;
    srcUrl: string;
    isYt: boolean;
}

const InstitutionScreen: FC = () => {
    const styles = useStyles();

    const playerRef = React.useRef<VideoJsPlayer | null>(null);

    const [videoList, setVideoList] = useState([] as IVideo[])

    const handlePlayerReady = (player: VideoJsPlayer) => {
        playerRef.current = player;

        // you can handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };

    const urlS3 = "https://dbechope.s3.sa-east-1.amazonaws.com/images_site"

    useEffect(() => {
        const videos = [
            { title: "echope - Natal", description: "", srcUrl: `dGPykr2PQC4`, isYt: true },
            { title: "echope - Parque", description: "", srcUrl: `mbhj5gkUHLs`, isYt: true },
            { title: "Chope Amstel é na echope", description: "", srcUrl: `KT-FCMJ0zu0`, isYt: true },
            { title: "Novo app - echope", description: "", srcUrl: `O0me8Oa8XiM`, isYt: true },
            { title: "Roleta da sorte - echope", description: "", srcUrl: `7P-ycuNT3_c`, isYt: true },
            { title: "O Especialista - Férias", description: "", srcUrl: `oHb2KIITsdc`, isYt: true },
            { title: "O Especialista - Dia dos Namorados", description: "", srcUrl: `w9ANgbEKxpQ`, isYt: true },
            { title: "O Especialista - Feriado", description: "", srcUrl: `DYSQ3p1Jr28`, isYt: true },
            { title: "O Especialista - Mês das Mulheres", description: "", srcUrl: `67y7L_GYAjM`, isYt: true },
            { title: "O Especialista - Carnaval", description: "", srcUrl: `WBSVs-OX5gw`, isYt: true },
            { title: "O Especialista", description: "", srcUrl: `9fkrMCUo2po`, isYt: true },
            { title: "Institucional", description: "", srcUrl: `${urlS3}/Institucional.mp4`, isYt: false },
            { title: "A invenção do óbvio", description: "", srcUrl: "yPfcz-B7jk4", isYt: true },
            { title: "echope - ninguém merece cerveja pelando", description: "", srcUrl: "b0vBz8JXL-0", isYt: true },
            { title: "echope é mais verão", description: "", srcUrl: "CrsfGkdSM4s", isYt: true },
            { title: "Acabou o Barril?", description: "", srcUrl: `${urlS3}/Barril.mp4`, isYt: false },
            { title: "Acabou a Cerveja?", description: "", srcUrl: `${urlS3}/Cerveja.mp4`, isYt: false },
            { title: "Acabou o Chope?", description: "", srcUrl: `${urlS3}/Chope.mp4`, isYt: false },
            { title: "Acabou a bebida?", description: "", srcUrl: `${urlS3}/Vinho.mp4`, isYt: false },
            { title: "echope Delivery", description: "", srcUrl: `${urlS3}/Delivery_echope.mp4`, isYt: false },
            { title: "Dia do Rock", description: "", srcUrl: `${urlS3}/the_beers.mp4`, isYt: false },
            { title: "Neste Natal, vamos entregar mais", description: "", srcUrl: `5UWMRKkeih0`, isYt: true },
            { title: "Quer uma mãozinha?", description: "", srcUrl: `UI0WXJqY5hE`, isYt: true },
            { title: "Peça no conforto de sua casa", description: "", srcUrl: `3KrW55AeRQs`, isYt: true },
            { title: "Baixe o app e pronto", description: "", srcUrl: `JBlGqNxklqs`, isYt: true },
            { title: "echope App", description: "", srcUrl: `${urlS3}/Echope_APP.mp4`, isYt: false },
            { title: "Receba em casa", description: "", srcUrl: `${urlS3}/receba_em_casa.mp4`, isYt: false },
            { title: "Ninguém merece cerveja pelando", description: "", srcUrl: `${urlS3}/ninguem_merece_cerveja_pelando_2.mp4`, isYt: false },
        ]
        setVideoList(videos)
    }, [])

    return (
        <section className={styles.container}>
            <SectionHeader variant="h4" title="Vídeos institucionais" />

            <div className={styles.videosContainer}>
                {videoList.map((item, index) => (
                    <div key={index} className={styles.card}>
                        {!item.isYt ? (
                            <VideoPlayer options={{
                                sources: [
                                    {
                                        src: item.srcUrl,
                                        type: "video/mp4"
                                    }
                                ]
                            }}
                                onReady={handlePlayerReady}
                            />
                        ) : (
                            <div className={styles.videoWrapper}>
                                <iframe src={`https://www.youtube.com/embed/${item.srcUrl}?modestbranding=1&showinfo=0`}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                                    allowFullScreen>
                                </iframe>
                            </div>
                        )}

                        <div className={styles.cardContent}>
                            <Typography variant="h6" color="primary">{item.title}</Typography>
                            <Typography variant="body2">{item.description}</Typography>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        marginLeft: "2%",
        width: "96%",
        display: "flex",
        flexDirection: "column",
        marginBottom: 30,
        [theme.breakpoints.down("sm")]: {
            marginLeft: "1%",
            width: "98%",
        }
    },
    videosContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexFlow: "row wrap",
        marginTop: 30,
        gap: '30px 1%',
    },
    card: {
        [theme.breakpoints.up("xs")]: {
            width: '99%',
        },
        [theme.breakpoints.up("sm")]: {
            width: '32%',
        },
        [theme.breakpoints.up("md")]: {
            width: '24%',
        }
    },
    videoWrapper: {
        position: 'relative',
        paddingBottom: '56.25%', /* 16:9 */
        height: '0',
        '& iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        }
    },
    cardContent: {
        paddingLeft: 5,
    }
}));

export default InstitutionScreen;